@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');


@font-face {
  font-family: "Built Titling";
  font-style: normal;
  font-display: swap;
  src: url("/fonts/regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Built Titling";
  font-style: normal;
  font-display: swap;
  src: url("/fonts/bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Built Titling";
  font-style: normal;
  font-display: swap;
  src: url("/fonts/light.ttf");
  font-weight: 300;
}

.titlefont {
  font-family: "Built Titling", sans-serif;
}

body {
  //font-family: "Built Titling", sans-serif;
  font-family: "Montserrat", sans-serif;
}

.bg-image {
  background: url("/img/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}